.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(248, 248, 248, 0.1);
  text-align: center;
}

.footer-container :first-child {
  text-align: left;
}

.footer-container :last-child {
  text-align: right;
}

.footer-box {
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 28%;
}

.footer-link {
  color: rgb(248, 248, 248);
  text-decoration: none !important;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .footer-container :first-child {
    text-align: center;
  }

  .footer-container :last-child {
    text-align: center;
  }

  .footer-box {
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
  }

  .footer {
    padding-top: 2rem;
  }
}
