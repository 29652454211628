.red-line::before {
  clip-path: polygon(
    74% 0,
    100% 0,
    100% 57%,
    97% 100%,
    25% 100%,
    0 100%,
    72% 48%
  );
  width: 100%;
  background: var(--bg-color-dark);
  background: linear-gradient(
    180deg,
    rgba(102, 16, 16, 1) 0%,
    rgb(119, 29, 29) 48%,
    rgb(107, 19, 19) 48%,
    rgba(237, 148, 148, 1) 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
}

.icon-logo-wrapper {
  position: absolute;
  top: 65%;
  right: 14%;
  transform: translateY(-50%);
}

#icon-logo {
  width: 500px;
  height: 500px;
}

@media screen and (min-width: 2500px) {
  .icon-logo-wrapper {
    top: 60%;
    right: 16%;
  }

  #icon-logo {
    width: 550px;
    height: 550px;
  }
}

@media screen and (min-width: 3350px) and (max-height: 1500px) {
  .icon-logo-wrapper {
    right: 28%;
    top: 61%;
  }

  .red-line::before {
    clip-path: polygon(
      68% 0,
      100% 0,
      100% 53%,
      96% 100%,
      0 100%,
      0 100%,
      66% 46%
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 46%,
      rgb(107, 19, 19) 46%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (min-width: 4000px) and (max-height: 1500px) {
  .icon-logo-wrapper {
    top: 62% !important;
    right: 36% !important;
  }
  .red-line::before {
    clip-path: polygon(
      60% 0,
      83% 0,
      82% 49%,
      78% 100%,
      0 100%,
      0 100%,
      57% 49%
    ) !important;
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 49%,
      rgb(107, 19, 19) 49%,
      rgba(237, 148, 148, 1) 100%
    ) !important;
  }

  #icon-logo {
    width: 600px !important;
    height: 600px !important;
  }
}

@media screen and (min-width: 3800px) {
  .icon-logo-wrapper {
    top: 60%;
    right: 29%;
  }

  #icon-logo {
    width: 700px;
    height: 700px;
  }

  .red-line::before {
    clip-path: polygon(
      65% 0,
      100% 0,
      100% 60%,
      94% 100%,
      0 100%,
      0 100%,
      61% 50%
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 49%,
      rgb(107, 19, 19) 49%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (max-width: 1700px) {
  .icon-logo-wrapper {
    right: 16%;
  }

  #icon-logo {
    width: 450px;
    height: 450px;
  }
}

@media screen and (max-width: 1400px) {
  .icon-logo-wrapper {
    top: 68%;
    right: 17%;
  }

  #icon-logo {
    width: 410px;
    height: 410px;
  }
}

@media screen and (max-width: 1200px) {
  .icon-logo-wrapper {
    top: 65%;
    right: 17%;
  }

  #icon-logo {
    width: 340px;
    height: 340px;
  }
}

@media screen and (max-width: 1050px) {
  .icon-logo-wrapper {
    top: 73%;
    right: 14%;
  }

  #icon-logo {
    width: 320px;
    height: 320px;
  }

  .red-line::before {
    clip-path: polygon(
      100% 0,
      100% 63%,
      91% 100%,
      0 100%,
      0 86%,
      71% 59%,
      77% 0
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 59%,
      rgb(107, 19, 19) 59%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (max-width: 650px) {
  .icon-logo-wrapper {
    top: 70%;
  }

  #icon-logo {
    width: 250px;
    height: 250px;
  }

  .red-line::before {
    clip-path: polygon(
      76% 0,
      100% 0,
      100% 64%,
      94% 100%,
      0 100%,
      0 82%,
      74% 64%
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 64%,
      rgb(107, 19, 19) 64%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (max-width: 500px) {
  .icon-logo-wrapper {
    top: 74%;
    right: 10%;
  }

  #icon-logo {
    width: 200px;
    height: 200px;
  }
}
