#skills {
  padding-top: calc(60px + 4rem);
  padding-bottom: calc(60px + 4rem);
}

.pos-wrapper {
  overflow: hidden;
  position: relative;
}

.skills-circles:before {
  left: calc(50% + 200px);
  width: 900px;
  height: 900px;
  content: "";
  border-radius: 50%;
  border: 1px solid rgb(248, 248, 248);
  position: absolute;
  transform: translateY(7%);
}

.skills-circles:after {
  left: calc(50% - 280px);
  width: 1600px;
  height: 1600px;
  content: "";
  border-radius: 50%;
  border: 1px solid rgb(248, 248, 248);
  position: absolute;
  transform: translateY(-18%);
}

.skills-circles {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  opacity: 0.3;
  z-index: 1;
}

.skills-circle-large:after {
  left: calc(50% - 850px);
  width: 2300px;
  height: 2300px;
  content: "";
  border-radius: 50%;
  border: 1px solid rgb(248, 248, 248);
  position: absolute;
  transform: translateY(-30%);
}

.skills-circle-large {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  opacity: 0.3;
  z-index: 1;
}

#skills-text {
  background-color: #661010;
  position: relative;
  z-index: 2;
  padding: 0.75rem 0;
}

.subheading-white::before {
  background: rgb(248, 248, 248);
}

.subheading-white {
  color: rgb(248, 248, 248);
  background: #661010;
  z-index: 1;
  width: 10%;
}

.skills-card {
  width: 30%;
}

.skills-card:hover {
  z-index: 1;
}

.card-heading {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 0px;
}

.icons-skills {
  vertical-align: middle;
  height: 32px;
  width: relative;
  margin: 0px 8px 14px 4px;
}

.pictures {
  text-align: center;
  margin-bottom: 20px;
}

#skills-this-site:hover {
  position: relative;
  z-index: 1;
}

.tech-box:first-child {
  padding-right: 3rem;
}

.tech-box:last-child {
  padding-left: 3rem;
}

.tech-card {
  width: 100%;
  text-align: center;
}

.tech-box {
  width: 30%;
}

.skills-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

@media screen and (max-width: 1800px) {
  .icons-skills {
    height: 28px;
    margin: 0px 6px 14px 3px;
  }
}

@media screen and (max-width: 1700px) {
  .skills-circles:before {
    width: 950px;
    height: 950px;
    left: calc(50% + 140px);
    transform: translateY(5%);
  }

  .skills-circles:after {
    left: calc(50% - 240px);
    transform: translateY(-20%);
  }

  .skills-circle-large:after {
    left: calc(50% - 730px);
  }
}

@media screen and (max-width: 1400px) {
  #skills {
    padding-top: calc(40px + 3rem);
    padding-bottom: calc(40px + 3rem);
  }

  .skills-circle-large:after {
    left: calc(50% - 650px);
    transform: translateY(-27%);
  }

  .skills-circles:before {
    width: 700px;
    height: 700px;
    left: calc(50% + 140px);
    transform: translateY(15%);
  }

  .skills-circles:after {
    left: calc(50% - 200px);
    transform: translateY(-17%);
  }
}

@media screen and (max-width: 1200px) {
  .tech-box:first-child {
    padding-right: 0.8rem;
  }

  .tech-box:last-child {
    padding-left: 0.8rem;
  }

  .skills-circle-large:after {
    left: calc(50% - 550px);
    transform: translateY(-27%);
  }

  .skills-circles:before {
    width: 700px;
    height: 700px;
    left: calc(50% + 110px);
    transform: translateY(10%);
  }

  .skills-circles:after {
    left: calc(50% - 160px);
    width: 1400px;
    height: 1400px;
    transform: translateY(-17%);
  }
}

@media screen and (max-width: 1000px) {
  .skills-card {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
    width: 90%;
  }

  .tech-card {
    width: 90%;
  }

  .tech-box {
    width: 90%;
    margin-top: 2rem;
  }

  .tech-box:first-child {
    padding-right: 0rem;
  }

  .tech-box:last-child {
    padding-left: 0rem;
  }

  #skills-text {
    display: block;
    text-align: center;
  }

  .skills-circles:before {
    left: calc(50% - 262.5px);
    width: 525px;
    height: 525px;
    transform: translateY(95%);
  }

  .skills-circles:after {
    left: calc(50% - 500px);
    width: 1000px;
    height: 1000px;
    transform: translateY(22%);
  }

  .skills-circle-large:after {
    left: calc(50% - 725px);
    width: 1450px;
    height: 1450px;
    transform: translateY(1%);
  }
}

@media screen and (max-width: 800px) {
  .skills-card .tech-card {
    width: 100%;
  }

  .tech-box {
    width: 100%;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .skills-card .tech-card {
    width: 100%;
  }

  .tech-box {
    width: 100%;
    margin-top: 3rem;
  }

  .skills-circles:before {
    left: calc(50% - 250px);
    width: 500px;
    height: 500px;
    transform: translateY(108%);
  }

  .skills-circles:after {
    left: calc(50% - 550px);
    width: 1100px;
    height: 1100px;
    transform: translateY(26%);
  }

  .skills-circle-large:after {
    left: calc(50% - 775px);
    width: 1550px;
    height: 1500px;
    transform: translateY(1%);
  }
}

@media screen and (max-width: 390px) {
  .skills-card .tech-card {
    width: 100%;
  }

  .skills-circles:before {
    left: calc(50% - 237.5px);
    width: 475px;
    height: 475px;
    transform: translateY(122%);
  }

  .skills-circles:after {
    left: calc(50% - 500px);
    width: 1000px;
    height: 1000px;
    transform: translateY(30%);
  }

  .skills-circle-large:after {
    left: calc(50% - 750px);
    width: 1500px;
    height: 1500px;
    transform: translateY(4%);
  }
}
