@media screen and (max-width: 1000px) {
  .responsive {
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 1rem !important;
  }

  .responsive-reverse {
    flex-direction: column-reverse !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 1rem !important;
  }
}
