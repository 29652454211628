.admin-form-delete {
  font: Verdana;
  text-align: left;
  margin-left: 5%;
}

.button {
  margin-top: 1%;
  margin-bottom: 3%;
  width: 5%;
}

.input-delete {
  margin-left: 1%;
  width: 13%;
  margin-right: 1%;
}

.input:hover {
  border: 2px solid black;
}
