.admin-form-update {
    font: Verdana;
    text-align: left;
    margin-left: 5%;
  }
  
  .button {
    margin-top: 1%;
    margin-bottom: 3%;
    width: 5%;
  }
  
  .input {
    margin-left: 10%;
    width: 180%;
  }
  
  .input-large {
    box-sizing: border-box;
    height: 80px;
  }
  
  .input:hover {
    border: 2px solid black;
  }