.legal-main-headline {
  margin-top: 154px;
  padding-bottom: 6rem;
}

.legal-heading {
  text-align: left;
  margin-bottom: 24px;
  margin-top: 0px;
}

.links {
  color: #ab1818 !important;
}

.legal-card {
  margin-bottom: 3rem;
}
