.card-white {
  border-radius: 35px;
  box-shadow: none;
  transition: all ease-in-out 0.25s;
  text-align: justify;
  padding: 2rem;
  background-color: white;
  box-shadow: 10px 10px 20px #ebe9e9, -10px -10px 20px #ffffff;
}

.card-white:hover {
  box-shadow: 17.5px 17.5px 35px #bebebe, -17.5px -17.5px 35px #ffffff;
  /*box-shadow: 15px 15px 30px #dd9191, -15px -15px 30px #ececec;*/
  transform: scale(1.03) !important;
}

.card-red {
  border-radius: 35px;
  box-shadow: none;
  transition: all ease-in-out 0.25s;
  text-align: justify;
  padding: 2rem;
  background-color: #661010;
  /* box-shadow: 7.5px 7.5px 15px #5a1414, -7.5px -7.5px 15px #6b1818;*/
  color: rgb(248, 248, 248);
}

.card-red:hover {
  /*box-shadow: 12.5px 12.5px 25px #862d2d, -12.5px -12.5px 25px #862d2d;*/
  /*box-shadow: 15px 15px 30px #dd9191, -15px -15px 30px #ececec;*/
  box-shadow: 17.5px 17.5px 35px #520f0f, -17.5px -17.5px 35px #641010;
  transform: scale(1.03);
  background-color: rgb(105, 23, 23);
}

.card-pos {
  position: relative;
}

.extend-card-marker {
  font-size: 1.7rem;
  position: absolute;
  top: 1.7rem;
  right: 1.8rem;
  transition: all ease-in-out 0.25s;
}

.rotate-extend-card {
  transform: rotate(45deg);
}

@media screen and (max-width: 1200px) {
  .card-white {
    border-radius: 30px;
    padding: 2.25rem 1.75rem;
    margin-bottom: 1.75rem;
  }

  .card-red {
    border-radius: 30px;
    padding: 1.75rem;
    margin-bottom: 1.5rem;
  }
}
