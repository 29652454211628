#about {
  margin-bottom: 12rem;
  margin-top: 9rem;
}

.about-container {
  background-color: white;
  box-shadow: 20px 20px 40px #ebe9e9, -20px -20px 40px #ffffff;
  border-radius: 35px;
  padding: 5rem 7rem;
}

.about-box-text {
  width: 60%;
  text-align: justify;
}

.about-box-picture {
  width: 30%;
  text-align: right;
}

.social-icon {
  height: 32px;
}

.picture {
  width: 320px;
  height: relative;
  border-radius: 30px;
  box-shadow: 12.5px 17.5px 25px #dad9d9, -12.5px -17.5px 25px #ebe9e9;
  transition: all ease-in-out 0.3s;
}

.picture:hover {
  transform: scale(1.03);
  transition: all ease-in-out 0.3s;
  box-shadow: 16px 23px 31px #ddd8d8, -16px -23px 31px #ebe9e9;
}

@media screen and (max-width: 2000px) {
  .about-container {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 1800px) {
  .about-container {
    padding: 3rem 3rem;
  }

  .picture {
    width: 280px;
  }
}

@media screen and (max-width: 1400px) {
  #about {
    padding-top: calc(40px + 3rem);
  }

  .about-container {
    padding: 3rem 1.5rem;
  }

  .picture {
    width: 245px;
  }

  .social-icon {
    height: 28px;
  }
}

@media screen and (max-width: 1000px) {
  .about-container {
    padding: 5rem 12rem;
  }

  .about-box-text {
    width: 270px;
    padding: 0rem;
  }

  .about-box-picture {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }

  .picture {
    width: 270px;
    box-shadow: 10px 12.5px 17.5px #dad9d9, -10px 12.5px 17.5px #ebe9e9;
  }
}

@media screen and (max-width: 800px) {
  .about-container {
    padding: 3rem 2rem;
  }

  .about-box-text {
    width: 230px;
    padding: 0rem;
  }

  .about-box-picture {
    margin-top: 0rem;
    width: 100%;
    text-align: center;
  }

  .picture {
    width: 230px;
  }
}
