.logo {
  height: 50px;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 4;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.navbar-wrapper.scrolled {
  top: 1.5rem;
  border-radius: 20px;
  background: white;
  box-shadow: 10px 10px 20px rgba(126, 126, 126, 0.5),
    -10px -10px 20px rgba(150, 150, 150, 0.5);
}

.gridItem:hover {
  color: black; /*#ab1818;*/
  fill: black; /*#ab1818;*/
}

.gridItem {
  align-items: center;
}

.burger {
  cursor: pointer;
  align-self: center;
  margin: auto 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  transition: all 0.5s;
  align-self: center;
  z-index: 3;
}

.change {
  background-color: #661010;
}

.bars {
  margin: 15px auto;
  width: 30px;
}

.bar1,
.bar2 {
  width: 30px;
  height: 4px;
  background-color: #aba8a8;
  margin: 6px 0px;
  transition: 0.25s;
  border-radius: 5px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, -2.5px);
  transform: rotate(-45deg) translate(-7.5px, -1px);
  background: rgb(222, 222, 222);
  margin: 9px 6px;
}

.change .bar2 {
  -webkit-transform: rotate(45deg) translate(-10px, -1px);
  transform: rotate(45deg) translate(-10px, -1px);
  background: rgb(222, 222, 222);
  margin: 9px 6px;
}

nav {
  margin-top: auto;
  margin-left: auto;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.nav-links {
  color: #aba8a8;
  text-decoration: none !important;
}

ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.nav-icon {
  z-index: 3 !important;
}

.nav-list-item {
  font-size: 1.3rem;
  margin-left: 4rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  z-index: 1;
}

.nav-hashlink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #aba8a8;
  fill: #aba8a8;
  transition: 0.3s;
}

.nav-hashlink:hover {
  color: black !important; /*#ab1818;*/
  fill: black !important; /*#ab1818;*/
}

.nav-list-item:first-child {
  margin-left: 0;
  margin-right: 5rem;
}

.nav-list-item.a {
  display: inline-block;
  margin-top: 5px;
}

@media screen and (max-width: 1400px) {
  .logo {
    height: 40px;
  }
}

@media screen and (max-width: 1150px) {
  .navbar-item {
    display: none !important;
  }

  .navbar-wrapper {
    padding-bottom: 0rem;
    padding-top: 0rem;
  }

  .navbar-wrapper.scrolled {
    top: 1.3rem;
  }
}
