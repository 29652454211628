@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg-color-light: rgb(248, 248, 248);
  --bg-color-dark: #661010;
}

html {
  font-size: 100%;
}

textarea {
  resize: none;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
  background-color: rgb(248, 248, 248) !important;
  background-color: var(--bg-color-light) !important;
  z-index: 9998;
}

.subheading::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 52px;
  height: 2px;
  background: #661010;
  background: var(--bg-color-dark);
}

.subheading {
  position: relative;
  margin-bottom: 24px;
  padding-left: 68px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}

.red {
  color: #ab1818;
}

.section-heading {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.toggle {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  transition: all ease-in-out 0.25s;
  background: rgb(248, 248, 248);
  /* nur firefox */
  scrollbar-width: none;
  z-index: 2;
}

.toggle::before {
  -webkit-clip-path: polygon(0 0, 27% 0, 27% 48%, 100% 100%, 15% 100%, 0 48%);
          clip-path: polygon(0 0, 27% 0, 27% 48%, 100% 100%, 15% 100%, 0 48%);
  width: 100%;
  background: #661010;
  background: var(--bg-color-dark);
  background: linear-gradient(
    180deg,
    rgba(102, 16, 16, 1) 0%,
    rgb(119, 29, 29) 48%,
    rgb(107, 19, 19) 48%,
    rgba(237, 148, 148, 1) 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  z-index: -1;
}

.hide {
  display: none;
}

.red-svg {
  fill: #ab1818;
}

.default-svg {
  height: 2rem;
  width: 2rem;
  padding-right: 0.25rem;
}

/* chrome und rest */
.toggle::-webkit-scrollbar {
  display: none;
}

.background {
  background-color: rgb(248, 248, 248);
  background-color: var(--bg-color-light);
}

.background-red {
  background-color: #661010;
  background-color: var(--bg-color-dark);
  color: rgb(248, 248, 248);
}

.landing-page {
  height: 100vh;
}

h1 {
  font-size: 3.5rem;
  margin: 0;
}

.headline {
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
}

@media screen and (min-width: 2500px) {
  h1 {
    font-size: 3.8rem;
  }

  .headline {
    top: 45%;
  }
}

@media screen and (min-width: 4000px) and (max-height: 1500px) {
  h1 {
    font-size: 4rem !important;
  }

  .headline {
    top: 45% !important;
  }
}

@media screen and (min-width: 3800px) {
  h1 {
    font-size: 5rem;
  }

  .headline {
    top: 46%;
  }
}

@media screen and (max-width: 1700px) {
  h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1400px) {
  h1 {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 1200px) {
  .toggle::before {
    -webkit-clip-path: polygon(
      50% 0,
      50% 40%,
      100% 69%,
      100% 100%,
      15% 100%,
      0 40%,
      0 0
    );
            clip-path: polygon(
      50% 0,
      50% 40%,
      100% 69%,
      100% 100%,
      15% 100%,
      0 40%,
      0 0
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 40%,
      rgb(107, 19, 19) 40%,
      rgba(237, 148, 148, 1) 100%
    );
  }

  .section-heading {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  html {
    font-size: 80%;
  }

  h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1050px) {
  .subheading::before {
    width: 33px;
    top: 10px;
  }

  .subheading {
    position: relative;
    margin-bottom: 11px;
    margin-top: 11px;
    padding-left: 40px;
    font-size: 16px;
  }

  .headline {
    top: 41%;
  }
}

@media screen and (max-height: 900px) {
  .headline {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .headline {
    top: 38%;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 1.8rem;
    margin: 0;
  }

  .headline {
    top: 35%;
  }
}

.admin-form-create {
  font: Verdana;
  text-align: left;
  margin-left: 5%;
}

.button {
  margin-top: 1%;
  margin-bottom: 3%;
  width: 5%;
}

.input {
  margin-left: 10%;
  width: 180%;
}

.input-large {
  box-sizing: border-box;
  height: 80px;
}

.input:hover {
  border: 2px solid black;
}

.admin-form-delete {
  font: Verdana;
  text-align: left;
  margin-left: 5%;
}

.button {
  margin-top: 1%;
  margin-bottom: 3%;
  width: 5%;
}

.input-delete {
  margin-left: 1%;
  width: 13%;
  margin-right: 1%;
}

.input:hover {
  border: 2px solid black;
}

.admin-form-table {
  margin: 3% 5% 3% 5%;
}

.admin-form-update {
    font: Verdana;
    text-align: left;
    margin-left: 5%;
  }
  
  .button {
    margin-top: 1%;
    margin-bottom: 3%;
    width: 5%;
  }
  
  .input {
    margin-left: 10%;
    width: 180%;
  }
  
  .input-large {
    box-sizing: border-box;
    height: 80px;
  }
  
  .input:hover {
    border: 2px solid black;
  }
.content-wrapper {
  max-width: 1620px;
  margin: auto;
  padding: 3.5rem 4rem;
  transition: 0.3s;
}

@media screen and (max-width: 1800px) {
  .content-wrapper {
    margin-left: calc(calc(0.083 * calc(100vw - 8rem)) + 0rem);
    margin-right: calc(calc(0.083 * calc(100vw - 8rem)) + 0rem);
  }
}

@media screen and (max-width: 1400px) {
  .content-wrapper {
    padding: 2rem 2.5rem;
  }
}

@media screen and (max-width: 800px) {
  .content-wrapper {
    padding: 2rem 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .content-wrapper {
    padding: 2rem 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(248, 248, 248, 0.1);
  text-align: center;
}

.footer-container :first-child {
  text-align: left;
}

.footer-container :last-child {
  text-align: right;
}

.footer-box {
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 28%;
}

.footer-link {
  color: rgb(248, 248, 248);
  text-decoration: none !important;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .footer-container :first-child {
    text-align: center;
  }

  .footer-container :last-child {
    text-align: center;
  }

  .footer-box {
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
  }

  .footer {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .responsive {
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .responsive-reverse {
    flex-direction: column-reverse !important;
    justify-content: space-between !important;
    align-items: center !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
}

.scroll-icon {
  position: absolute;
  bottom: 7%;
  height: 3.8rem;
  padding: 0.75rem;
  border-radius: 50%;
  background: white;
  margin-right: 1rem;
  box-shadow: 4px 4px 8px rgba(236, 236, 236, 0.4),
    -4px -4px 8px rgb(244, 244, 244, 0.5);
  transition: all ease-in-out 0.4s;
  z-index: 2;
}

.scroll-icon:hover {
  transform: translateY(6px);
}

/*.headline-animation {
  display: block;
}

.headline-animation:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 5rem;
  background: rgb(248, 248, 248);
  z-index: 1;
  transform-origin: 100% 0;
}*/


.logo {
  height: 50px;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 4;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.navbar-wrapper.scrolled {
  top: 1.5rem;
  border-radius: 20px;
  background: white;
  box-shadow: 10px 10px 20px rgba(126, 126, 126, 0.5),
    -10px -10px 20px rgba(150, 150, 150, 0.5);
}

.gridItem:hover {
  color: black; /*#ab1818;*/
  fill: black; /*#ab1818;*/
}

.gridItem {
  align-items: center;
}

.burger {
  cursor: pointer;
  align-self: center;
  margin: auto 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  transition: all 0.5s;
  align-self: center;
  z-index: 3;
}

.change {
  background-color: #661010;
}

.bars {
  margin: 15px auto;
  width: 30px;
}

.bar1,
.bar2 {
  width: 30px;
  height: 4px;
  background-color: #aba8a8;
  margin: 6px 0px;
  transition: 0.25s;
  border-radius: 5px;
}

.change .bar1 {
  transform: rotate(-45deg) translate(-7.5px, -1px);
  background: rgb(222, 222, 222);
  margin: 9px 6px;
}

.change .bar2 {
  transform: rotate(45deg) translate(-10px, -1px);
  background: rgb(222, 222, 222);
  margin: 9px 6px;
}

nav {
  margin-top: auto;
  margin-left: auto;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.nav-links {
  color: #aba8a8;
  text-decoration: none !important;
}

ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.nav-icon {
  z-index: 3 !important;
}

.nav-list-item {
  font-size: 1.3rem;
  margin-left: 4rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  z-index: 1;
}

.nav-hashlink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #aba8a8;
  fill: #aba8a8;
  transition: 0.3s;
}

.nav-hashlink:hover {
  color: black !important; /*#ab1818;*/
  fill: black !important; /*#ab1818;*/
}

.nav-list-item:first-child {
  margin-left: 0;
  margin-right: 5rem;
}

.nav-list-item.a {
  display: inline-block;
  margin-top: 5px;
}

@media screen and (max-width: 1400px) {
  .logo {
    height: 40px;
  }
}

@media screen and (max-width: 1150px) {
  .navbar-item {
    display: none !important;
  }

  .navbar-wrapper {
    padding-bottom: 0rem;
    padding-top: 0rem;
  }

  .navbar-wrapper.scrolled {
    top: 1.3rem;
  }
}

.contact-padding {
  padding: 0rem 4rem;
  z-index: 100;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0;
  transform: translateY(-50%);
}

.contact {
  background-color: white;
  border-radius: 40px;
  padding: 1.75rem 11rem 3rem 11rem;
  margin-top: 8rem;
  box-shadow: 20px 20px 40px rgb(235, 233, 233, 0.25),
    -15px -15px 30px rgba(255, 255, 255, 0.25);
}

.contact-headline {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 0;
}

.contact-form {
  width: 50%;
}

.contact-data {
  color: #666565;
  font-size: 1.15rem;
}

.contact-data-element {
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
}

.contact-data-icon {
  margin-right: 1.5rem;
}

.input-wrapper {
  position: relative;
  margin-bottom: 1.75rem;
}

.contact-input {
  height: 3.5rem;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: 2px solid rgb(242, 242, 242);
  background-color: rgb(242, 242, 242);
  padding: 1.2rem 3rem 1.2rem 1.2rem;
  color: #666565;
  transition: border 0.25s;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.contact-button {
  background: #661010;
  border: none;
  height: 3rem;
  border-radius: 10px;
  width: 10rem;
  color: rgb(242, 242, 242);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgb(242, 242, 242);
  cursor: pointer;
  transition: all ease-in-out 0.25s;
}

.contact-input:focus {
  border: 2px solid #ab1818;
}

.svg-transition {
  transition: all ease-in-out 0.25s;
}

.submit-icon {
  height: 24px;
  width: relative;
  margin-left: 0.2rem;
}

.contact-button:focus {
  outline: none;
}

.contact-button:hover {
  transform: scale(1.03);
}

.contact-success-message-heading {
  font-size: 2.5rem;
  margin: 0;
  color: #ab1818;
  font-weight: bold;
}

.contact-success-message-body {
  font-size: 2rem;
  width: 60%;
  margin: 0 0 4rem 0;
}

.large {
  height: 9rem;
  box-sizing: border-box;
}

.contact-input-icon {
  fill: #aba8a8;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 3.5rem;
  width: 4rem;
  top: 0;
  right: 0;
  align-items: center;
}

.contact-socials-flex {
  display: flex;
  align-items: center;
}

.contact-socials-item {
  color: #aba8a8;
  text-decoration: none;
}

.contact-socials-icon {
  margin-right: 4px;
  height: 26px;
  width: relative;
  vertical-align: middle;
  fill: #aba8a8;
}

@media screen and (max-width: 1800px) {
  .contact {
    padding: 2rem 6rem;
  }

  .contact-data-icon {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .contact {
    padding: 2rem 4rem;
    box-shadow: 12.5px 12.5px 25px rgb(235, 233, 233, 0.35),
      -15px -15px 30px rgba(255, 255, 255, 0.25);
  }

  .contact-padding {
    padding: 0rem 2.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .contact-form {
    width: 100%;
  }

  .contact-data {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
  }

  .input-wrapper {
    margin-bottom: 1.25rem;
  }

  .contact-data-bottom {
    margin-right: 1.5rem;
  }

  .submit-icon {
    height: 18px;
  }

  .contact-success-message-body {
    font-size: 1.5rem;
    width: 60%;
    margin: 0 0 3rem 0;
  }
}

@media screen and (max-width: 800px) {
  .contact-padding {
    padding: 0rem 2rem;
  }

  .contact {
    box-shadow: 7.5px 7.5px 15px rgb(235, 233, 233, 0.3),
      -10px -10px 20px rgba(255, 255, 255, 0.3);
    padding: 1.5rem 2rem;
  }

  .contact-headline {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-padding {
    padding: 0rem 1rem;
  }

  .contact-socials-item {
    display: none;
  }

  .contact-data {
    display: none;
  }
}

.card-white {
  border-radius: 35px;
  box-shadow: none;
  transition: all ease-in-out 0.25s;
  text-align: justify;
  padding: 2rem;
  background-color: white;
  box-shadow: 10px 10px 20px #ebe9e9, -10px -10px 20px #ffffff;
}

.card-white:hover {
  box-shadow: 17.5px 17.5px 35px #bebebe, -17.5px -17.5px 35px #ffffff;
  /*box-shadow: 15px 15px 30px #dd9191, -15px -15px 30px #ececec;*/
  transform: scale(1.03) !important;
}

.card-red {
  border-radius: 35px;
  box-shadow: none;
  transition: all ease-in-out 0.25s;
  text-align: justify;
  padding: 2rem;
  background-color: #661010;
  /* box-shadow: 7.5px 7.5px 15px #5a1414, -7.5px -7.5px 15px #6b1818;*/
  color: rgb(248, 248, 248);
}

.card-red:hover {
  /*box-shadow: 12.5px 12.5px 25px #862d2d, -12.5px -12.5px 25px #862d2d;*/
  /*box-shadow: 15px 15px 30px #dd9191, -15px -15px 30px #ececec;*/
  box-shadow: 17.5px 17.5px 35px #520f0f, -17.5px -17.5px 35px #641010;
  transform: scale(1.03);
  background-color: rgb(105, 23, 23);
}

.card-pos {
  position: relative;
}

.extend-card-marker {
  font-size: 1.7rem;
  position: absolute;
  top: 1.7rem;
  right: 1.8rem;
  transition: all ease-in-out 0.25s;
}

.rotate-extend-card {
  transform: rotate(45deg);
}

@media screen and (max-width: 1200px) {
  .card-white {
    border-radius: 30px;
    padding: 2.25rem 1.75rem;
    margin-bottom: 1.75rem;
  }

  .card-red {
    border-radius: 30px;
    padding: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

/*.rating-grid {
  display: grid;
  grid-template-columns: 50fr 50fr;
  width: 90%;
}
*/

.rating-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.skill-name {
  text-align: left;
  padding-left: 2.5rem;
  flex-basis: 70%;
}

.rating {
  text-align: right;
  padding-right: 2.5rem;
  flex-basis: 30%;
}

@media screen and (max-width: 1400px) {
  .skill-name {
    text-align: left;
    padding-left: 1rem;
    flex-basis: 70%;
  }

  .rating {
    text-align: right;
    padding-right: 1rem;
    flex-basis: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .skill-name {
    padding-left: 14rem;
  }

  .rating {
    padding-right: 14rem;
  }
}

@media screen and (max-width: 800px) {
  .skill-name {
    padding-left: 10rem;
  }

  .rating {
    padding-right: 10rem;
  }
}

@media screen and (max-width: 650px) {
  .skill-name {
    padding-left: 3rem;
  }

  .rating {
    padding-right: 3rem;
  }
}

#skills {
  padding-top: calc(60px + 4rem);
  padding-bottom: calc(60px + 4rem);
}

.pos-wrapper {
  overflow: hidden;
  position: relative;
}

.skills-circles:before {
  left: calc(50% + 200px);
  width: 900px;
  height: 900px;
  content: "";
  border-radius: 50%;
  border: 1px solid rgb(248, 248, 248);
  position: absolute;
  transform: translateY(7%);
}

.skills-circles:after {
  left: calc(50% - 280px);
  width: 1600px;
  height: 1600px;
  content: "";
  border-radius: 50%;
  border: 1px solid rgb(248, 248, 248);
  position: absolute;
  transform: translateY(-18%);
}

.skills-circles {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  opacity: 0.3;
  z-index: 1;
}

.skills-circle-large:after {
  left: calc(50% - 850px);
  width: 2300px;
  height: 2300px;
  content: "";
  border-radius: 50%;
  border: 1px solid rgb(248, 248, 248);
  position: absolute;
  transform: translateY(-30%);
}

.skills-circle-large {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  opacity: 0.3;
  z-index: 1;
}

#skills-text {
  background-color: #661010;
  position: relative;
  z-index: 2;
  padding: 0.75rem 0;
}

.subheading-white::before {
  background: rgb(248, 248, 248);
}

.subheading-white {
  color: rgb(248, 248, 248);
  background: #661010;
  z-index: 1;
  width: 10%;
}

.skills-card {
  width: 30%;
}

.skills-card:hover {
  z-index: 1;
}

.card-heading {
  text-align: center;
  margin-bottom: 24px;
  margin-top: 0px;
}

.icons-skills {
  vertical-align: middle;
  height: 32px;
  width: relative;
  margin: 0px 8px 14px 4px;
}

.pictures {
  text-align: center;
  margin-bottom: 20px;
}

#skills-this-site:hover {
  position: relative;
  z-index: 1;
}

.tech-box:first-child {
  padding-right: 3rem;
}

.tech-box:last-child {
  padding-left: 3rem;
}

.tech-card {
  width: 100%;
  text-align: center;
}

.tech-box {
  width: 30%;
}

.skills-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

@media screen and (max-width: 1800px) {
  .icons-skills {
    height: 28px;
    margin: 0px 6px 14px 3px;
  }
}

@media screen and (max-width: 1700px) {
  .skills-circles:before {
    width: 950px;
    height: 950px;
    left: calc(50% + 140px);
    transform: translateY(5%);
  }

  .skills-circles:after {
    left: calc(50% - 240px);
    transform: translateY(-20%);
  }

  .skills-circle-large:after {
    left: calc(50% - 730px);
  }
}

@media screen and (max-width: 1400px) {
  #skills {
    padding-top: calc(40px + 3rem);
    padding-bottom: calc(40px + 3rem);
  }

  .skills-circle-large:after {
    left: calc(50% - 650px);
    transform: translateY(-27%);
  }

  .skills-circles:before {
    width: 700px;
    height: 700px;
    left: calc(50% + 140px);
    transform: translateY(15%);
  }

  .skills-circles:after {
    left: calc(50% - 200px);
    transform: translateY(-17%);
  }
}

@media screen and (max-width: 1200px) {
  .tech-box:first-child {
    padding-right: 0.8rem;
  }

  .tech-box:last-child {
    padding-left: 0.8rem;
  }

  .skills-circle-large:after {
    left: calc(50% - 550px);
    transform: translateY(-27%);
  }

  .skills-circles:before {
    width: 700px;
    height: 700px;
    left: calc(50% + 110px);
    transform: translateY(10%);
  }

  .skills-circles:after {
    left: calc(50% - 160px);
    width: 1400px;
    height: 1400px;
    transform: translateY(-17%);
  }
}

@media screen and (max-width: 1000px) {
  .skills-card {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
    width: 90%;
  }

  .tech-card {
    width: 90%;
  }

  .tech-box {
    width: 90%;
    margin-top: 2rem;
  }

  .tech-box:first-child {
    padding-right: 0rem;
  }

  .tech-box:last-child {
    padding-left: 0rem;
  }

  #skills-text {
    display: block;
    text-align: center;
  }

  .skills-circles:before {
    left: calc(50% - 262.5px);
    width: 525px;
    height: 525px;
    transform: translateY(95%);
  }

  .skills-circles:after {
    left: calc(50% - 500px);
    width: 1000px;
    height: 1000px;
    transform: translateY(22%);
  }

  .skills-circle-large:after {
    left: calc(50% - 725px);
    width: 1450px;
    height: 1450px;
    transform: translateY(1%);
  }
}

@media screen and (max-width: 800px) {
  .skills-card .tech-card {
    width: 100%;
  }

  .tech-box {
    width: 100%;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .skills-card .tech-card {
    width: 100%;
  }

  .tech-box {
    width: 100%;
    margin-top: 3rem;
  }

  .skills-circles:before {
    left: calc(50% - 250px);
    width: 500px;
    height: 500px;
    transform: translateY(108%);
  }

  .skills-circles:after {
    left: calc(50% - 550px);
    width: 1100px;
    height: 1100px;
    transform: translateY(26%);
  }

  .skills-circle-large:after {
    left: calc(50% - 775px);
    width: 1550px;
    height: 1500px;
    transform: translateY(1%);
  }
}

@media screen and (max-width: 390px) {
  .skills-card .tech-card {
    width: 100%;
  }

  .skills-circles:before {
    left: calc(50% - 237.5px);
    width: 475px;
    height: 475px;
    transform: translateY(122%);
  }

  .skills-circles:after {
    left: calc(50% - 500px);
    width: 1000px;
    height: 1000px;
    transform: translateY(30%);
  }

  .skills-circle-large:after {
    left: calc(50% - 750px);
    width: 1500px;
    height: 1500px;
    transform: translateY(4%);
  }
}

* {
  box-sizing: border-box;
}

#timetable {
  padding-top: calc(60px + 5rem);
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 100%; /*1600px;*/
  margin: 0 auto;
  padding-bottom: 5rem;
}

/* The actual timeline (the vertical ruler) */
.timeline::before {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #aba8a8;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  margin-bottom: 4rem;
}

/* Container around content */
.container {
  position: relative;
  background-color: inherit;
  width: 50%;
}

.left {
  padding: 10px 40px 10px 0px;
}

.right {
  padding: 10px 0px 10px 40px;
}

/* The circles on the timeline */
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #ab1818;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -17px;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  #timetable {
    padding-top: calc(40px + 3rem);
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1250px) {
  .container {
    width: 100%;
    padding-left: 65px;
    padding-right: 15px;
  }

  .timeline {
    max-width: 100%;
  }

  .timeline::before {
    left: 31px;
  }

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 17px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }

  /* The circles on the timeline */
  .container::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    right: -15px;
    background-color: white;
    border: 4px solid #ab1818;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1200px) {
  .container {
    width: 100%;
    padding-left: 60px;
    padding-right: 15px;
  }

  .timeline {
    max-width: 100%;
  }
}

.timespan {
  font-weight: bold;
  font-size: 115%;
  color: #ab1818;
}

.heading {
  font-size: 105%;
  margin: 0.5rem 0;
  font-weight: bold;
  text-align: left;
}

.card-details {
  margin-top: 1.5rem;
}

.category-spacing {
  width: 3px;
  background-color: black;
}

.details-heading {
  -webkit-margin-before: 0.3rem;
          margin-block-start: 0.3rem;
  -webkit-margin-after: 0.7rem;
          margin-block-end: 0.7rem;
  text-align: left;
}

.icons {
  height: 35px;
  width: relative;
  margin: 0px 15px 0px 5px;
}

@media screen and (max-width: 1100px) {
  .icons {
    height: 24px;
    width: relative;
    margin: 0px 7px 0px 1px;
  }
}

.red-line::before {
  -webkit-clip-path: polygon(
    74% 0,
    100% 0,
    100% 57%,
    97% 100%,
    25% 100%,
    0 100%,
    72% 48%
  );
          clip-path: polygon(
    74% 0,
    100% 0,
    100% 57%,
    97% 100%,
    25% 100%,
    0 100%,
    72% 48%
  );
  width: 100%;
  background: var(--bg-color-dark);
  background: linear-gradient(
    180deg,
    rgba(102, 16, 16, 1) 0%,
    rgb(119, 29, 29) 48%,
    rgb(107, 19, 19) 48%,
    rgba(237, 148, 148, 1) 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
}

.icon-logo-wrapper {
  position: absolute;
  top: 65%;
  right: 14%;
  transform: translateY(-50%);
}

#icon-logo {
  width: 500px;
  height: 500px;
}

@media screen and (min-width: 2500px) {
  .icon-logo-wrapper {
    top: 60%;
    right: 16%;
  }

  #icon-logo {
    width: 550px;
    height: 550px;
  }
}

@media screen and (min-width: 3350px) and (max-height: 1500px) {
  .icon-logo-wrapper {
    right: 28%;
    top: 61%;
  }

  .red-line::before {
    -webkit-clip-path: polygon(
      68% 0,
      100% 0,
      100% 53%,
      96% 100%,
      0 100%,
      0 100%,
      66% 46%
    );
            clip-path: polygon(
      68% 0,
      100% 0,
      100% 53%,
      96% 100%,
      0 100%,
      0 100%,
      66% 46%
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 46%,
      rgb(107, 19, 19) 46%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (min-width: 4000px) and (max-height: 1500px) {
  .icon-logo-wrapper {
    top: 62% !important;
    right: 36% !important;
  }
  .red-line::before {
    -webkit-clip-path: polygon(
      60% 0,
      83% 0,
      82% 49%,
      78% 100%,
      0 100%,
      0 100%,
      57% 49%
    ) !important;
            clip-path: polygon(
      60% 0,
      83% 0,
      82% 49%,
      78% 100%,
      0 100%,
      0 100%,
      57% 49%
    ) !important;
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 49%,
      rgb(107, 19, 19) 49%,
      rgba(237, 148, 148, 1) 100%
    ) !important;
  }

  #icon-logo {
    width: 600px !important;
    height: 600px !important;
  }
}

@media screen and (min-width: 3800px) {
  .icon-logo-wrapper {
    top: 60%;
    right: 29%;
  }

  #icon-logo {
    width: 700px;
    height: 700px;
  }

  .red-line::before {
    -webkit-clip-path: polygon(
      65% 0,
      100% 0,
      100% 60%,
      94% 100%,
      0 100%,
      0 100%,
      61% 50%
    );
            clip-path: polygon(
      65% 0,
      100% 0,
      100% 60%,
      94% 100%,
      0 100%,
      0 100%,
      61% 50%
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 49%,
      rgb(107, 19, 19) 49%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (max-width: 1700px) {
  .icon-logo-wrapper {
    right: 16%;
  }

  #icon-logo {
    width: 450px;
    height: 450px;
  }
}

@media screen and (max-width: 1400px) {
  .icon-logo-wrapper {
    top: 68%;
    right: 17%;
  }

  #icon-logo {
    width: 410px;
    height: 410px;
  }
}

@media screen and (max-width: 1200px) {
  .icon-logo-wrapper {
    top: 65%;
    right: 17%;
  }

  #icon-logo {
    width: 340px;
    height: 340px;
  }
}

@media screen and (max-width: 1050px) {
  .icon-logo-wrapper {
    top: 73%;
    right: 14%;
  }

  #icon-logo {
    width: 320px;
    height: 320px;
  }

  .red-line::before {
    -webkit-clip-path: polygon(
      100% 0,
      100% 63%,
      91% 100%,
      0 100%,
      0 86%,
      71% 59%,
      77% 0
    );
            clip-path: polygon(
      100% 0,
      100% 63%,
      91% 100%,
      0 100%,
      0 86%,
      71% 59%,
      77% 0
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 59%,
      rgb(107, 19, 19) 59%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (max-width: 650px) {
  .icon-logo-wrapper {
    top: 70%;
  }

  #icon-logo {
    width: 250px;
    height: 250px;
  }

  .red-line::before {
    -webkit-clip-path: polygon(
      76% 0,
      100% 0,
      100% 64%,
      94% 100%,
      0 100%,
      0 82%,
      74% 64%
    );
            clip-path: polygon(
      76% 0,
      100% 0,
      100% 64%,
      94% 100%,
      0 100%,
      0 82%,
      74% 64%
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 64%,
      rgb(107, 19, 19) 64%,
      rgba(237, 148, 148, 1) 100%
    );
  }
}

@media screen and (max-width: 500px) {
  .icon-logo-wrapper {
    top: 74%;
    right: 10%;
  }

  #icon-logo {
    width: 200px;
    height: 200px;
  }
}

#about {
  margin-bottom: 12rem;
  margin-top: 9rem;
}

.about-container {
  background-color: white;
  box-shadow: 20px 20px 40px #ebe9e9, -20px -20px 40px #ffffff;
  border-radius: 35px;
  padding: 5rem 7rem;
}

.about-box-text {
  width: 60%;
  text-align: justify;
}

.about-box-picture {
  width: 30%;
  text-align: right;
}

.social-icon {
  height: 32px;
}

.picture {
  width: 320px;
  height: relative;
  border-radius: 30px;
  box-shadow: 12.5px 17.5px 25px #dad9d9, -12.5px -17.5px 25px #ebe9e9;
  transition: all ease-in-out 0.3s;
}

.picture:hover {
  transform: scale(1.03);
  transition: all ease-in-out 0.3s;
  box-shadow: 16px 23px 31px #ddd8d8, -16px -23px 31px #ebe9e9;
}

@media screen and (max-width: 2000px) {
  .about-container {
    padding: 4rem 4rem;
  }
}

@media screen and (max-width: 1800px) {
  .about-container {
    padding: 3rem 3rem;
  }

  .picture {
    width: 280px;
  }
}

@media screen and (max-width: 1400px) {
  #about {
    padding-top: calc(40px + 3rem);
  }

  .about-container {
    padding: 3rem 1.5rem;
  }

  .picture {
    width: 245px;
  }

  .social-icon {
    height: 28px;
  }
}

@media screen and (max-width: 1000px) {
  .about-container {
    padding: 5rem 12rem;
  }

  .about-box-text {
    width: 270px;
    padding: 0rem;
  }

  .about-box-picture {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }

  .picture {
    width: 270px;
    box-shadow: 10px 12.5px 17.5px #dad9d9, -10px 12.5px 17.5px #ebe9e9;
  }
}

@media screen and (max-width: 800px) {
  .about-container {
    padding: 3rem 2rem;
  }

  .about-box-text {
    width: 230px;
    padding: 0rem;
  }

  .about-box-picture {
    margin-top: 0rem;
    width: 100%;
    text-align: center;
  }

  .picture {
    width: 230px;
  }
}

.legal-main-headline {
  margin-top: 154px;
  padding-bottom: 6rem;
}

.legal-heading {
  text-align: left;
  margin-bottom: 24px;
  margin-top: 0px;
}

.links {
  color: #ab1818 !important;
}

.legal-card {
  margin-bottom: 3rem;
}

