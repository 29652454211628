.card-details {
  margin-top: 1.5rem;
}

.category-spacing {
  width: 3px;
  background-color: black;
}

.details-heading {
  margin-block-start: 0.3rem;
  margin-block-end: 0.7rem;
  text-align: left;
}

.icons {
  height: 35px;
  width: relative;
  margin: 0px 15px 0px 5px;
}

@media screen and (max-width: 1100px) {
  .icons {
    height: 24px;
    width: relative;
    margin: 0px 7px 0px 1px;
  }
}
