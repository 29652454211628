.scroll-icon {
  position: absolute;
  bottom: 7%;
  height: 3.8rem;
  padding: 0.75rem;
  border-radius: 50%;
  background: white;
  margin-right: 1rem;
  box-shadow: 4px 4px 8px rgba(236, 236, 236, 0.4),
    -4px -4px 8px rgb(244, 244, 244, 0.5);
  transition: all ease-in-out 0.4s;
  z-index: 2;
}

.scroll-icon:hover {
  transform: translateY(6px);
}

/*.headline-animation {
  display: block;
}

.headline-animation:after {
  content: " ";
  position: absolute;
  left: 0;
  width: 100%;
  height: 5rem;
  background: rgb(248, 248, 248);
  z-index: 1;
  transform-origin: 100% 0;
}*/
