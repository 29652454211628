/*.rating-grid {
  display: grid;
  grid-template-columns: 50fr 50fr;
  width: 90%;
}
*/

.rating-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.skill-name {
  text-align: left;
  padding-left: 2.5rem;
  flex-basis: 70%;
}

.rating {
  text-align: right;
  padding-right: 2.5rem;
  flex-basis: 30%;
}

@media screen and (max-width: 1400px) {
  .skill-name {
    text-align: left;
    padding-left: 1rem;
    flex-basis: 70%;
  }

  .rating {
    text-align: right;
    padding-right: 1rem;
    flex-basis: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .skill-name {
    padding-left: 14rem;
  }

  .rating {
    padding-right: 14rem;
  }
}

@media screen and (max-width: 800px) {
  .skill-name {
    padding-left: 10rem;
  }

  .rating {
    padding-right: 10rem;
  }
}

@media screen and (max-width: 650px) {
  .skill-name {
    padding-left: 3rem;
  }

  .rating {
    padding-right: 3rem;
  }
}
