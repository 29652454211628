.contact-padding {
  padding: 0rem 4rem;
  z-index: 100;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.contact {
  background-color: white;
  border-radius: 40px;
  padding: 1.75rem 11rem 3rem 11rem;
  margin-top: 8rem;
  box-shadow: 20px 20px 40px rgb(235, 233, 233, 0.25),
    -15px -15px 30px rgba(255, 255, 255, 0.25);
}

.contact-headline {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 0;
}

.contact-form {
  width: 50%;
}

.contact-data {
  color: #666565;
  font-size: 1.15rem;
}

.contact-data-element {
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
}

.contact-data-icon {
  margin-right: 1.5rem;
}

.input-wrapper {
  position: relative;
  margin-bottom: 1.75rem;
}

.contact-input {
  height: 3.5rem;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: 2px solid rgb(242, 242, 242);
  background-color: rgb(242, 242, 242);
  padding: 1.2rem 3rem 1.2rem 1.2rem;
  color: #666565;
  transition: border 0.25s;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.contact-button {
  background: #661010;
  border: none;
  height: 3rem;
  border-radius: 10px;
  width: 10rem;
  color: rgb(242, 242, 242);
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgb(242, 242, 242);
  cursor: pointer;
  transition: all ease-in-out 0.25s;
}

.contact-input:focus {
  border: 2px solid #ab1818;
}

.svg-transition {
  transition: all ease-in-out 0.25s;
}

.submit-icon {
  height: 24px;
  width: relative;
  margin-left: 0.2rem;
}

.contact-button:focus {
  outline: none;
}

.contact-button:hover {
  transform: scale(1.03);
}

.contact-success-message-heading {
  font-size: 2.5rem;
  margin: 0;
  color: #ab1818;
  font-weight: bold;
}

.contact-success-message-body {
  font-size: 2rem;
  width: 60%;
  margin: 0 0 4rem 0;
}

.large {
  height: 9rem;
  box-sizing: border-box;
}

.contact-input-icon {
  fill: #aba8a8;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 3.5rem;
  width: 4rem;
  top: 0;
  right: 0;
  align-items: center;
}

.contact-socials-flex {
  display: flex;
  align-items: center;
}

.contact-socials-item {
  color: #aba8a8;
  text-decoration: none;
}

.contact-socials-icon {
  margin-right: 4px;
  height: 26px;
  width: relative;
  vertical-align: middle;
  fill: #aba8a8;
}

@media screen and (max-width: 1800px) {
  .contact {
    padding: 2rem 6rem;
  }

  .contact-data-icon {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .contact {
    padding: 2rem 4rem;
    box-shadow: 12.5px 12.5px 25px rgb(235, 233, 233, 0.35),
      -15px -15px 30px rgba(255, 255, 255, 0.25);
  }

  .contact-padding {
    padding: 0rem 2.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .contact-form {
    width: 100%;
  }

  .contact-data {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
  }

  .input-wrapper {
    margin-bottom: 1.25rem;
  }

  .contact-data-bottom {
    margin-right: 1.5rem;
  }

  .submit-icon {
    height: 18px;
  }

  .contact-success-message-body {
    font-size: 1.5rem;
    width: 60%;
    margin: 0 0 3rem 0;
  }
}

@media screen and (max-width: 800px) {
  .contact-padding {
    padding: 0rem 2rem;
  }

  .contact {
    box-shadow: 7.5px 7.5px 15px rgb(235, 233, 233, 0.3),
      -10px -10px 20px rgba(255, 255, 255, 0.3);
    padding: 1.5rem 2rem;
  }

  .contact-headline {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-padding {
    padding: 0rem 1rem;
  }

  .contact-socials-item {
    display: none;
  }

  .contact-data {
    display: none;
  }
}
