.content-wrapper {
  max-width: 1620px;
  margin: auto;
  padding: 3.5rem 4rem;
  transition: 0.3s;
}

@media screen and (max-width: 1800px) {
  .content-wrapper {
    margin-left: calc(calc(0.083 * calc(100vw - 8rem)) + 0rem);
    margin-right: calc(calc(0.083 * calc(100vw - 8rem)) + 0rem);
  }
}

@media screen and (max-width: 1400px) {
  .content-wrapper {
    padding: 2rem 2.5rem;
  }
}

@media screen and (max-width: 800px) {
  .content-wrapper {
    padding: 2rem 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .content-wrapper {
    padding: 2rem 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
