* {
  box-sizing: border-box;
}

#timetable {
  padding-top: calc(60px + 5rem);
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 100%; /*1600px;*/
  margin: 0 auto;
  padding-bottom: 5rem;
}

/* The actual timeline (the vertical ruler) */
.timeline::before {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #aba8a8;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  margin-bottom: 4rem;
}

/* Container around content */
.container {
  position: relative;
  background-color: inherit;
  width: 50%;
}

.left {
  padding: 10px 40px 10px 0px;
}

.right {
  padding: 10px 0px 10px 40px;
}

/* The circles on the timeline */
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #ab1818;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -17px;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  #timetable {
    padding-top: calc(40px + 3rem);
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1250px) {
  .container {
    width: 100%;
    padding-left: 65px;
    padding-right: 15px;
  }

  .timeline {
    max-width: 100%;
  }

  .timeline::before {
    left: 31px;
  }

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 17px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }

  /* The circles on the timeline */
  .container::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    right: -15px;
    background-color: white;
    border: 4px solid #ab1818;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1200px) {
  .container {
    width: 100%;
    padding-left: 60px;
    padding-right: 15px;
  }

  .timeline {
    max-width: 100%;
  }
}

.timespan {
  font-weight: bold;
  font-size: 115%;
  color: #ab1818;
}

.heading {
  font-size: 105%;
  margin: 0.5rem 0;
  font-weight: bold;
  text-align: left;
}
