:root {
  --bg-color-light: rgb(248, 248, 248);
  --bg-color-dark: #661010;
}

html {
  font-size: 100%;
}

textarea {
  resize: none;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
  background-color: var(--bg-color-light) !important;
  z-index: 9998;
}

.subheading::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 52px;
  height: 2px;
  background: var(--bg-color-dark);
}

.subheading {
  position: relative;
  margin-bottom: 24px;
  padding-left: 68px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}

.red {
  color: #ab1818;
}

.section-heading {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.toggle {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  transition: all ease-in-out 0.25s;
  background: rgb(248, 248, 248);
  /* nur firefox */
  scrollbar-width: none;
  z-index: 2;
}

.toggle::before {
  clip-path: polygon(0 0, 27% 0, 27% 48%, 100% 100%, 15% 100%, 0 48%);
  width: 100%;
  background: var(--bg-color-dark);
  background: linear-gradient(
    180deg,
    rgba(102, 16, 16, 1) 0%,
    rgb(119, 29, 29) 48%,
    rgb(107, 19, 19) 48%,
    rgba(237, 148, 148, 1) 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  z-index: -1;
}

.hide {
  display: none;
}

.red-svg {
  fill: #ab1818;
}

.default-svg {
  height: 2rem;
  width: 2rem;
  padding-right: 0.25rem;
}

/* chrome und rest */
.toggle::-webkit-scrollbar {
  display: none;
}

.background {
  background-color: var(--bg-color-light);
}

.background-red {
  background-color: var(--bg-color-dark);
  color: rgb(248, 248, 248);
}

.landing-page {
  height: 100vh;
}

h1 {
  font-size: 3.5rem;
  margin: 0;
}

.headline {
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
}

@media screen and (min-width: 2500px) {
  h1 {
    font-size: 3.8rem;
  }

  .headline {
    top: 45%;
  }
}

@media screen and (min-width: 4000px) and (max-height: 1500px) {
  h1 {
    font-size: 4rem !important;
  }

  .headline {
    top: 45% !important;
  }
}

@media screen and (min-width: 3800px) {
  h1 {
    font-size: 5rem;
  }

  .headline {
    top: 46%;
  }
}

@media screen and (max-width: 1700px) {
  h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1400px) {
  h1 {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 1200px) {
  .toggle::before {
    clip-path: polygon(
      50% 0,
      50% 40%,
      100% 69%,
      100% 100%,
      15% 100%,
      0 40%,
      0 0
    );
    background: linear-gradient(
      180deg,
      rgba(102, 16, 16, 1) 0%,
      rgb(119, 29, 29) 40%,
      rgb(107, 19, 19) 40%,
      rgba(237, 148, 148, 1) 100%
    );
  }

  .section-heading {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  html {
    font-size: 80%;
  }

  h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1050px) {
  .subheading::before {
    width: 33px;
    top: 10px;
  }

  .subheading {
    position: relative;
    margin-bottom: 11px;
    margin-top: 11px;
    padding-left: 40px;
    font-size: 16px;
  }

  .headline {
    top: 41%;
  }
}

@media screen and (max-height: 900px) {
  .headline {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .headline {
    top: 38%;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 1.8rem;
    margin: 0;
  }

  .headline {
    top: 35%;
  }
}
